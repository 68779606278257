<template>  
  <div>
    <div class="welcome_msg" >
      SSO Login...
    </div>
    
  </div>
</template>

<script>
import router from "../router";
import BasicInfo from "../BasicInfo.js";
import axios from "axios";
import EventBus from '@/eventBus.js';

export default {
  components: {
  },
  data: () => ({  
    cId : "",
  }),

  computed: {
  },

  created() {
  },
  mounted () {            
    // window.location.href='http://www.naver.com';
    // this.$store.dispatch('logout');    
    this.$store.state.UserInfo.isSSO = true;

    this.cId = this.$route.query.Id;     
    console.log("--------------------------------- Login", this.cId);

    if (this.cId == "" || this.cId == undefined) {
      router.push({name: "Login"});
    } else {
      var posData = {
        UserId: this.cId,
      }

      axios
        .post(BasicInfo.UIL_API + "GetUserInfoId", posData, {})
        .then(res => {
          if (res.data.ResultCd === "00") {            
            this.$store.dispatch("login", res.data);            
          } else if (res.data.ResultCd === "44") {
            EventBus.$emit("onShowMsg",true, res.data.Msg); 
            router.push({name: "Login"});
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg); 
            router.push({name: "Login"});
          }
        }).catch(err => {
          console.log(err)
          EventBus.$emit("onShowMsg",true, err); 
          router.push({name: "Login"});
        })
    }
  },
  methods: {

  }
};
</script>
<style scoped>
  .welcome_msg{    
    z-index: 0;
    position: fixed;    
    top:0;    
    width: 100vw;
    height: 100vh;        
    display: flex;
    justify-content: center;
    align-items: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    font-size: 50px;    
    color: #E31C39;
    /* color:rgb(139, 83, 18); */
    font-weight: bold;  
    text-shadow:
    -1px -1px 0 #FFFFFF,
    1px -1px 0 #FFFFFF,
    -1px 1px 0 #FFFFFF,
    1px 1px 0 #FFFFFF;     
    /* text-shadow: 0 0 5px #000000; */

    -webkit-animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 0.900) infinite;
  }
</style>